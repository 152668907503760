<template>
  <div>
    <div id="empty-layout">
      <Nuxt />
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'EmptyLayout',
  setup() {},
});
</script>

<style lang="scss">
#empty-layout {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
}
</style>
