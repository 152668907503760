<template>
  <div id="error" class="page-404">
    <div class="error-row">
      <div class="error-left">
        <p>{{ $t('Error Code : 404') }}</p>
        <h1 class="lato-medium">{{ $t('Oops!') }}</h1>
        <h2 class="lato-regular">
          {{ $t('We can’t seem to find the page you are looking for') }}
        </h2>
        <CustomButton :link="localePath('/')">
          {{ $t('Back To Home Page') }}
        </CustomButton>
      </div>
      <div class="error-right lato-bold">404</div>
    </div>
  </div>
</template>

<script>
import {
  useRouter,
  onMounted,
  useMeta,
  ref,
  useFetch,
} from '@nuxtjs/composition-api';
import seoHelpers from '~/helpers/seo/seoHelpers';
import { CustomButton } from '~/components/General/FormElements';

export default {
  name: 'ErrorLayout',
  // Forcing layout change on page leave.
  layout: 'errorLayout',
  components: {
    CustomButton,
  },
  head: {},
  setup() {
    const router = useRouter();
    const { getAlternates, getCanonical } = seoHelpers();
    const cookiebot = process && process.browser && window && window.Cookiebot;
    const alternates = ref([]);

    onMounted(() => {
      if (cookiebot) {
        window.addEventListener(
          'CookiebotOnDecline',
          () => {
            if (window.CookiebotDialog) window.location.reload();
          },
          false
        );
      }
    });

    useFetch(async () => {
      alternates.value = await getAlternates('error', true);
    });

    const pageTitle = 'Error Code: 404';
    useMeta(() => ({
      title: pageTitle,
      link: [...alternates.value, getCanonical()],
    }));

    return {
      router,
      pageTitle,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~@storefront-ui/vue/styles';
@import '@storefront-ui/shared/styles/helpers/_colors.scss';
@import '../assets/style/variables.scss';
#error {
  position: relative;
  margin: 0 auto;
  padding: 8.125rem 0;
  max-width: 69.375rem;
  @include to-mobile-max {
    padding: 3.75rem 0;
  }
  .error-row {
    display: var(--flex);
    align-items: var(--align-items);
    justify-content: space-between;
    @include to-mobile-max {
      flex-direction: var(--flex-col);
      text-align: center;
    }
    .error-left {
      max-width: 36%;
      text-align: left;
      @include to-tablet-max {
        max-width: 45%;
      }
      @include to-mobile-max {
        order: 2;
        max-width: 100%;
        text-align: center;
      }
    }
    .error-right {
      font-size: 14.0625rem;
      @include to-tablet-max {
        font-size: 12.5rem;
        line-height: 13.75rem;
      }
      @include to-mobile-max {
        font-size: 8.125rem;
        line-height: 8.75rem;
        text-align: center;
      }
    }
  }
  p {
    padding-bottom: 0px;
    color: var(--c-dark-grey);
    font-size: var(--font-size-20);
  }
  h1 {
    padding: 0;
    margin: 0 0 0.9375rem 0;
  }
  h2 {
    font-size: var(--font-size-28);
    color: var(--c-checkmark-grey);
    padding-bottom: 3.125rem;
    font-weight: normal;
    margin: 0;
    @include to-mobile-max {
      font-size: var(--font-size-20);
      padding-bottom: 1.875rem;
      line-height: var(--line-height-24);
    }
  }
}
</style>
