<template>
  <div>
    <IconSprite />
    <WishlistSidebar v-if="isWishlistSidebarOpen" />
    <CartSidebar v-if="isCartSidebarOpen" />
    <LoginAndRegisterModal v-if="loginAndRegisterModalOpen" />
    <LazyHydrate when-visible>
      <Notification />
    </LazyHydrate>
    <AppHeader />
    <div id="layout">
      <nuxt :key="route.fullPath" />
    </div>
    <LazyHydrate when-visible>
      <AppFooter />
    </LazyHydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';
import {
  useRoute,
  defineComponent,
  ref,
  useFetch,
  onMounted,
  useMeta,
  useContext,
} from '@nuxtjs/composition-api';
import AppHeader from '~/components/AppHeader.vue';
import CartSidebar from '~/components/CartSidebar.vue';
import LoginAndRegisterModal from '~/components/LoginAndRegisterModal.vue';
import { useGeminiConfiguration } from '~/composables/useGeminiConfiguration';
import { useUser, useUiState, useCart, useUserWishlists } from '~/composables/';
import Notification from '~/components/Notification.vue';
import { WishlistSidebar } from '~/components/Sidebars';

export default defineComponent({
  name: 'ErrorLayout',
  components: {
    LazyHydrate,
    AppHeader,
    WishlistSidebar,
    AppFooter: () =>
      import(/* webpackPrefetch: true */ '~/components/AppFooter.vue'),
    CartSidebar,
    LoginAndRegisterModal,
    Notification,
    IconSprite: () =>
      import(/* webpackPrefetch: true */ '~/components/General/IconSprite.vue'),
  },
  head: {},
  setup() {
    const route = useRoute();
    const {
      isAuthenticated,
      user,
      load: loadUser,
      loading: loadingUser,
      setAuthenticatedState,
    } = useUser();
    const {
      app: { $cookies },
    } = useContext();
    const { cart, load: loadCart } = useCart();
    const flag = ref(false);
    const cookiebot = process && process.browser && window && window.Cookiebot;
    const hasAlreadyDeclared = !!$cookies.get('CookieConsent');

    const {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      loginAndRegisterModalOpen,
    } = useUiState();
    const { loadWishlist, loadNumberOfItemsInWishlist } = useUserWishlists();

    const { loadConfiguration } = useGeminiConfiguration();

    onMounted(async () => {
      if (cookiebot && !hasAlreadyDeclared) {
        window.addEventListener(
          'CookiebotOnDecline',
          () => {
            if (window.CookiebotDialog) window.location.reload();
          },
          false
        );
      }
      if (!user.value && !loadingUser.value) {
        await loadUser();
      }
      if (!cart.value) {
        await loadCart();
      }
      await loadWishlist();
      await loadNumberOfItemsInWishlist();
    });

    useFetch(() => {
      setAuthenticatedState();
      loadConfiguration();
    });

    useMeta({
      htmlAttrs: {
        lang: route?.value?.path?.split('/')?.[1] === 'it' ? 'it' : 'en',
      },
    });

    return {
      ...useUserWishlists(),
      route,
      isAuthenticated,
      flag,
      user,
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      loginAndRegisterModalOpen,
      cart,
    };
  },
});
</script>

<style lang="scss">
@import '~@storefront-ui/vue/styles';
@import '@storefront-ui/shared/styles/helpers/_colors.scss';
@import '../assets/style/variables.scss';
@import '../assets/style/default.scss';
</style>
